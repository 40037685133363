html {
    font-family: 'Quicksand', sans-serif;
}

body {
    background-color: #000;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background: url("./assets/CC_Landingpage_BG.jpg") repeat center / cover;
    background-attachment: fixed;
}

button {
    transition: .3s ease all;

    &:not(:disabled):hover {
        transform: scale(1.05);
    }
}

.WelcomeTextContainer {
    color: white;
}

.headline {
    font-family: "ConneqtRegular";

    @media (max-width: 650px){
        font-size: 18px;
    }
}

.btn.styled {
    font-family: 'Architects Daughter', cursive;
}

.nav-tabs {
    a {
        color: #000;
    }
}

label {
    margin-bottom: 10px;
}

.badge {
    padding: 8px 12px;
}

.badge-success {
    background-color: aquamarine;
    color: #000;
}

.nav-link {
    cursor: pointer;
}

.fade-in-animate {
    animation: fadeIn 5s;
    -webkit-animation: fadeIn 5s;
    -moz-animation: fadeIn 5s;
    -o-animation: fadeIn 5s;
    -ms-animation: fadeIn 5s;
  }
  @keyframes fadeIn {
    0% {opacity:0;}
    100% {opacity:1;}
  }

  @-moz-keyframes fadeIn {
    0% {opacity:0;}
    100% {opacity:1;}
  }

  @-webkit-keyframes fadeIn {
    0% {opacity:0;}
    100% {opacity:1;}
  }

  @-o-keyframes fadeIn {
    0% {opacity:0;}
    100% {opacity:1;}
  }

  @-ms-keyframes fadeIn {
    0% {opacity:0;}
    100% {opacity:1;}
  }
