.dropzone{
    background-color: #f0f8f7;
    border: 1px solid #b0bab8;
    padding: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 25px;
}

.dropzone input{
    height: 110px;
    width: 100%;

}

.filepicker{

    cursor: pointer;
    opacity: 0;
}

.inputOverlay{
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;

    pointer-events: none
}

.inputOverlay div{
    text-align: center;
    font-size: 12px;

}

.inputs-wrapper {
    input {
        width: 100%;
        margin-bottom: 12px;
        box-sizing: border-box;
        padding-left: 20px;
    }
}

.dropzone:hover{
    background-color: #8bb3c7;
    transition: .3s ease;
}

.fillFormContainer_form--input {
    background-color: #f0f8f7;
    border: 1px solid #b0bab8;
    height: 32px;
    padding: 0px 12px;
}

.fillFormContainer_form--input#name {
    margin-right: 10px;
}

.fillFormContainer_form textarea{
    resize: none;
    margin: 25px 4px;
    border-style: none;
}

.link-btn {
    width: 40%;
    background: rgb(45,129,201);
    background: linear-gradient(90deg, rgba(45,129,201,1) 0%, rgba(17,215,216,1) 100%);
    border-style: none;
    border-radius: 25px;
    padding: 10px;
    font-size: 16px;
    letter-spacing: 1.5px;
    text-align: center;
    font-weight: bold;
    outline: none;
    margin: 40px auto 0;
    display: block;
    width: 228px;
    height: 50px;
    color: #fff;
    text-transform: uppercase;
}

.fillFormContainer_form--buttonGrey{
    width: 40%;
    letter-spacing: 1.5px;
    background-color: #7e909e;
    border-style: none;
    border-radius: 25px;
    margin: 0px auto;
    padding: 10px;
    font-size: 20px;
    font-weight: bolder;
}


.fillFormContainer_form--textarea{
    padding: 15px;
}

.verify--button{
    width: 170px;
    font-size: 20px;
    padding:10px;
    border-radius: 25px;
    border-style: none;
    color: white;
    letter-spacing: 2px;
    background: rgb(45,129,201);
    background: linear-gradient(90deg, rgba(45,129,201,1) 0%, rgba(17,215,216,1) 100%);
    position: relative;
    z-index: 2;
    cursor: pointer;
    outline: none;
}

.verify--code{
    position: relative;
    right: 10px;
    z-index: 1;
    border-color: white;
    border-style: solid;
    padding: 5px 0px 5px 25px;

}


@media (max-width: 650px){

    .verify--button{
        width: 130px;
    }

    .verify--code{
        width: 110px;
    }

}

.drop-text {
    max-width: 60%;
}
