@import url(//hello.myfonts.net/count/3ea635);
.main-container{max-width:1500px;min-height:80vh;padding:30px 0}.bodyFormContainer__form{padding:30px 12px;max-width:475px}.bodyFormContainer__body{padding:30px 12px}.welcomeTextContainer{letter-spacing:1.5px;display:flex;flex-direction:column;justify-content:center;color:white;font-weight:300}.welcomeTextContainer div{letter-spacing:3px;font-size:55px}.welcomeTextContainer p{line-height:0}.welcomeTextContainer__buttonGroup{display:flex;justify-content:space-around;position:relative;top:15px;left:10%;width:80%}.welcomeTextContainer__buttonGroup a{color:white;text-decoration:none;border-width:1px;padding:5px 20px;border-style:solid}.welcomeTextContainer__buttonGroup button{font-size:16px;padding:8px 20px;background-color:transparent;border-style:solid;border-color:white;color:white;border-width:1px}

.impressum__Container{
    color: white;
    font-size: 17px;
    font-weight: lighter;
}

@media (max-width: 650px){

    .impressum__Container{
        width: 80%;
        margin: auto;
    }

}


.verifyContainer{
    color: white;
    position: relative;
    bottom: 100px;
}

.verifyContainer--header{
    font-size: 32px;
}

.verifyContainer--text{
    width: 50%;
}

.verifyContainer--form{
    margin-top: 100px;
}

.verifyContainer--button{
    padding:10px 30px;
    letter-spacing: 1.5px;
    font-weight: bolder;
    color: white;
    font-size: 16px;
    background: rgb(45,129,201);
    background: linear-gradient(90deg, rgba(45,129,201,1) 0%, rgba(17,215,216,1) 100%);
    border-style: none;
    border-radius: 20px;
    position: relative;
    z-index: 2;
    right: 20px;
}


@media (max-width: 650px){
    .verifyContainer{
        color: white;
        margin: auto;
        position: unset;
       width: 95%;
       text-align: center;
    }

    .verifyContainer--text{
        margin: 15px auto;
        width: 85%;

        text-align: left;
    }

    .verifyContainer--form{

        margin-top: 50px;
        margin-left: 10%;


    }

}

.actionFormContainer_verify{box-shadow:-7.9px -6.2px 9.4px 0.6px rgba(23,31,31,0.21),8.1px 5.9px 9.4px 0.6px #193d44;border-radius:5px;width:630px}.actionFormContainer__form{width:100%;min-height:300px;background-color:#e6f4f2;border-bottom-left-radius:15px;border-bottom-right-radius:15px}.actionFormContainer__form .inner-form{padding:30px 20px}.actionFormContainer{position:relative}.mt-5{margin-top:10px}.text-style{background-color:darkslategray;min-height:31px;line-height:31px;border-radius:5px;padding-left:12px;text-align:left}

.dropzone{background-color:#f0f8f7;border:1px solid #b0bab8;padding:15px;display:flex;flex-direction:column;align-items:center;justify-content:center;margin-bottom:25px}.dropzone input{height:110px;width:100%}.filepicker{cursor:pointer;opacity:0}.inputOverlay{position:absolute;display:flex;flex-direction:column;align-items:center;pointer-events:none}.inputOverlay div{text-align:center;font-size:12px}.inputs-wrapper input{width:100%;margin-bottom:12px;box-sizing:border-box;padding-left:20px}.dropzone:hover{background-color:#8bb3c7;transition:.3s ease}.fillFormContainer_form--input{background-color:#f0f8f7;border:1px solid #b0bab8;height:32px;padding:0px 12px}.fillFormContainer_form--input#name{margin-right:10px}.fillFormContainer_form textarea{resize:none;margin:25px 4px;border-style:none}.link-btn{width:40%;background:#2d81c9;background:linear-gradient(90deg, #2d81c9 0%, #11d7d8 100%);border-style:none;border-radius:25px;padding:10px;font-size:16px;letter-spacing:1.5px;text-align:center;font-weight:bold;outline:none;margin:40px auto 0;display:block;width:228px;height:50px;color:#fff;text-transform:uppercase}.fillFormContainer_form--buttonGrey{width:40%;letter-spacing:1.5px;background-color:#7e909e;border-style:none;border-radius:25px;margin:0px auto;padding:10px;font-size:20px;font-weight:bolder}.fillFormContainer_form--textarea{padding:15px}.verify--button{width:170px;font-size:20px;padding:10px;border-radius:25px;border-style:none;color:white;letter-spacing:2px;background:#2d81c9;background:linear-gradient(90deg, #2d81c9 0%, #11d7d8 100%);position:relative;z-index:2;cursor:pointer;outline:none}.verify--code{position:relative;right:10px;z-index:1;border-color:white;border-style:solid;padding:5px 0px 5px 25px}@media (max-width: 650px){.verify--button{width:130px}.verify--code{width:110px}}.drop-text{max-width:60%}

.paymentContainer{
    color: #fff;
    height: 90%;
}

.payment__amount{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

.payment__amount--input{
    height: 32px;
    width: 80%;
    margin: 5px 5px;
    background-color: #AEE0F9;
    border-width: 1px;
    border-color: #72afce;
    border-style: solid;
    padding: 0px 25px 0px 25px;
    margin: 16px 0px;
}


.paypalContainer{
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    height: 250px;
    width: 100%;
}

.amountInput{
    position: relative;
    width: 30%;
    font-size: 28px;
    text-align: center;
    font-weight: bold;
    border-color: transparent;
    
}

.amountInput:focus{
    outline: none !important;
}


.amountInput::-webkit-outer-spin-button,
.amountInput::-webkit-inner-spin-button{
    -webkit-appearance: none;
    margin: 0;
}

.amountInput[type=number] {
    -moz-appearance: textfield;
  }

.form-nav{width:100%;display:flex;justify-content:space-around}.form-nav span{font-family:"ConneqtRegular";background-color:#819999;width:100%;display:flex;align-items:center;justify-content:center;text-align:center;height:50px;font-size:16px;text-transform:uppercase;text-decoration:none;color:#4d7a82}.form-nav span.active{background-color:#e6f4f2}.form-nav span:first-of-type{border-top-left-radius:15px}.form-nav span:last-of-type{border-top-right-radius:15px}@media (max-width: 650px){.linkTag{font-size:1rem}}

.actionFormContainer{box-shadow:-7.9px -6.2px 9.4px 0.6px rgba(23,31,31,0.21),8.1px 5.9px 9.4px 0.6px #193d44;border-radius:5px}.actionFormContainer__form{width:100%;min-height:500px;background-color:#e6f4f2;border-bottom-left-radius:15px;border-bottom-right-radius:15px}.actionFormContainer__form .inner-form{padding:30px 20px}.actionFormContainer{position:relative}.icon-arrow{background-image:url(/static/media/arrow-final_longpause.f6497d24.gif);background-repeat:no-repeat;background-size:contain;width:400px;height:150px;position:absolute;top:-140px;left:140px}@media (max-width: 920px){.icon-arrow{display:none}}

.certificateContainer{
    text-align: center;
}

.certificate--cert{
    text-align: center;
}

.certificateContainer p{
    font-size: 15px;
}

.certificate__buttonContainer{

   
    width: 100%;
    display: flex;
    justify-content: center;

}

.certificate--a{


    display: flex;
    justify-content: center;
    text-decoration: none;
    width: 60%;

}

.certificate--input{
    color: white;
    padding: 6px;
    letter-spacing: 1.4px;
    background-color: #4D87B5;
    border-color: transparent;
    border-radius: 6px;
    width: 100%;
}
.loginContainer{background-color:white;display:flex;justify-content:center;flex-direction:column;align-items:center;width:100%;max-width:320px;margin:6% auto 0 auto;padding:2%;border-radius:25px}.loginContainer__form{display:flex;flex-direction:column;padding:25px 0}.loginContainer__form--input{padding:15px;margin:5px auto 10px}.loginContainer__form--link{font-size:12px;text-align:center;margin-top:15px;text-decoration:none;color:black;background-color:transparent;border-color:transparent}

.card-wrapper{background-color:#fff;width:100%;max-width:1200px;margin:0 auto 5%;border-radius:15px;padding:20px}.settingsContainer__header{color:#4fa7d6;font-size:24px;font-weight:bold}.settingsContainer__header--form{display:flex;flex-direction:row;flex-wrap:wrap;width:100%}.settingsContainer__section{display:flex;flex-direction:column;margin:15px 15px;width:21%}.settingsContainer__section input{padding:10px;margin-top:10px;font-size:16px}.settingsContainer__button{padding:15px;background-color:#37A7D0;border-color:transparent;color:white;font-size:18px;margin-top:15%}.tab-content{padding:20px 10px}

.navBar{min-height:17vh;display:flex;align-items:center}.navBar__container{margin:0px 2% 0px auto}.navBar__container ul{display:flex;list-style:none;justify-content:center;margin:0px}.link{color:white;font-weight:100;text-decoration:none;margin:0px 25px 0px 25px}.navBar__container .home{height:26px;position:relative;bottom:3px}@media (max-width: 650px){.navBar__container{width:100%}.navBar{position:relative;left:-15px;min-height:10vh}.navBar__container ul{justify-content:space-between}.navBar__container .home{height:10px;position:relative;bottom:10px}.link{margin:0px;font-size:11px}}

.footer{width:100%}.footer__container ul{display:flex}.footerLink{text-decoration:none;color:white;font-size:x-small;font-weight:400;margin:0px 25px 0px 25px;position:relative;left:5px}.footerLink__pressKit{text-decoration:none;background-color:rgba(0,0,0,0.3);color:white;font-size:12px;letter-spacing:1px;padding:5px 15px 5px 35px}@media (max-width: 650px){.footer{margin-top:55px;position:relative;bottom:0px}.footer__container ul{justify-content:space-between}.footerLink{margin:0px;font-size:9px}}

th{color:chocolate}.dropzone{background-color:#f0f8f7;border:1px solid #b0bab8;padding:15px;display:flex;flex-direction:column;align-items:center;justify-content:center;margin-bottom:25px}.actionFormContainer__form{width:100%;min-height:300px;background-color:#e6f4f2;border-bottom-left-radius:15px;border-bottom-right-radius:15px}.actionFormContainer__form .inner-form{padding:30px 20px}.dropzone input{height:110px;width:100%}.filepicker{cursor:pointer;opacity:0}.inputOverlay{position:absolute;display:flex;flex-direction:column;align-items:center;pointer-events:none}.inputOverlay div{text-align:center;font-size:12px}.inputs-wrapper input{width:100%;margin-bottom:12px;box-sizing:border-box;padding-left:20px}.dropzone:hover{background-color:#8bb3c7;transition:.3s ease}.fillFormContainer_form--input{background-color:#f0f8f7;border:1px solid #b0bab8;height:32px;padding:0px 12px}.fillFormContainer_form--input#name{margin-right:10px}

.dataProtection__Container{
    background-color: white;
    width: 100vw;
    min-height: 100vh;
    position: absolute;
    top: 0;
    display: flex;
}

.dataProtection__Container--navbar{
    width: 100vw;
}

.dataProtection__Container--navbar ul{
    position: relative;
    left: 20%;
    top: 15%;
    font-size: 18px;
    list-style: none;
}

.dataProtection__Container--navbar ul li{
    margin: 10px 0px;
    color: rgb(44, 44, 233);
    text-decoration: underline;
    cursor: pointer;
}

.dataProtection__Container--info{
    margin-top: 50px;
}

/* http://meyerweb.com/eric/tools/css/reset/
   v2.0 | 20110126
   License: none (public domain)
*/

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
    display: block;
}

body {
    line-height: 1;
}

ol,
ul {
    list-style: none;
}

blockquote,
q {
    quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
    content: '';
    content: none;
}

table {
    border-collapse: collapse;
    border-spacing: 0;
}

a,
a:visited,
a:active,
a:focus {
    color: #fff;
    text-decoration: none;
    outline: 0;
}

form {
    width: 100%;
    box-sizing: border-box;
}

ul {
    list-style-type: none;
}

input {
    color: #000;
}

::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: #000;
}

::-moz-placeholder {
    /* Firefox 19+ */
    color: #000;
}

:-ms-input-placeholder {
    /* IE 10+ */
    color: #000;
}

:-moz-placeholder {
    /* Firefox 18- */
    color: #000;
}

button:not(:disabled) {
    cursor: pointer;
}
button {
    border-style: none;
}
button:disabled {
    opacity: 0.5;
}

/**
 * @license
 * MyFonts Webfont Build ID 4105781, 2021-06-18T10:54:18-0400
 *
 * The fonts listed in this notice are subject to the End User License
 * Agreement(s) entered into by the website owner. All other parties are
 * explicitly restricted from using the Licensed Webfonts(s).
 *
 * You may obtain a valid license at the URLs below.
 *
 * Webfont: ConneqtRegular by Roman Melikhov
 * URL: https://www.myfonts.com/fonts/roman-melikhov/conneqt/regular/
 * Copyright: Roman Melikhov
 *
 *
 *
 * © 2021 MyFonts Inc
*/


/* @import must be at top of file, otherwise CSS will not work */

@font-face {
  font-family: "ConneqtRegular";
  src: url(/static/media/font.17602043.woff2) format('woff2'), url(/static/media/font.990da49e.woff) format('woff');
}

html{font-family:'Quicksand', sans-serif}body{background-color:#000;-webkit-font-smoothing:antialiased;-moz-osx-font-smoothing:grayscale;background:url(/static/media/CC_Landingpage_BG.822b50f5.jpg) repeat center/cover;background-attachment:fixed}button{transition:.3s ease all}button:not(:disabled):hover{transform:scale(1.05)}.WelcomeTextContainer{color:white}.headline{font-family:"ConneqtRegular"}@media (max-width: 650px){.headline{font-size:18px}}.btn.styled{font-family:'Architects Daughter', cursive}.nav-tabs a{color:#000}label{margin-bottom:10px}.badge{padding:8px 12px}.badge-success{background-color:aquamarine;color:#000}.nav-link{cursor:pointer}.fade-in-animate{animation:fadeIn 5s;-webkit-animation:fadeIn 5s;-moz-animation:fadeIn 5s;-o-animation:fadeIn 5s;-ms-animation:fadeIn 5s}@keyframes fadeIn{0%{opacity:0}100%{opacity:1}}

