
.verifyContainer{
    color: white;
    position: relative;
    bottom: 100px;
}

.verifyContainer--header{
    font-size: 32px;
}

.verifyContainer--text{
    width: 50%;
}

.verifyContainer--form{
    margin-top: 100px;
}

.verifyContainer--button{
    padding:10px 30px;
    letter-spacing: 1.5px;
    font-weight: bolder;
    color: white;
    font-size: 16px;
    background: rgb(45,129,201);
    background: linear-gradient(90deg, rgba(45,129,201,1) 0%, rgba(17,215,216,1) 100%);
    border-style: none;
    border-radius: 20px;
    position: relative;
    z-index: 2;
    right: 20px;
}


@media (max-width: 650px){
    .verifyContainer{
        color: white;
        margin: auto;
        position: unset;
       width: 95%;
       text-align: center;
    }

    .verifyContainer--text{
        margin: 15px auto;
        width: 85%;

        text-align: left;
    }

    .verifyContainer--form{

        margin-top: 50px;
        margin-left: 10%;


    }

}
