/**
 * @license
 * MyFonts Webfont Build ID 4105781, 2021-06-18T10:54:18-0400
 *
 * The fonts listed in this notice are subject to the End User License
 * Agreement(s) entered into by the website owner. All other parties are
 * explicitly restricted from using the Licensed Webfonts(s).
 *
 * You may obtain a valid license at the URLs below.
 *
 * Webfont: ConneqtRegular by Roman Melikhov
 * URL: https://www.myfonts.com/fonts/roman-melikhov/conneqt/regular/
 * Copyright: Roman Melikhov
 *
 *
 *
 * © 2021 MyFonts Inc
*/


/* @import must be at top of file, otherwise CSS will not work */
@import url("//hello.myfonts.net/count/3ea635");

@font-face {
  font-family: "ConneqtRegular";
  src: url('./ConneqtRegular/font.woff2') format('woff2'), url('./ConneqtRegular/font.woff') format('woff');
}
