.loginContainer {
    background-color: white;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 320px;
    margin: 6% auto 0 auto;
    padding: 2%;
    border-radius: 25px;
}

.loginContainer__form{
    display: flex;
    flex-direction: column;
    padding: 25px 0;
}

.loginContainer__form--input{
    padding: 15px;
    margin: 5px auto 10px;
}

.loginContainer__form--link{
    font-size: 12px;
    text-align: center;
    margin-top: 15px;
    text-decoration: none;
    color: black;
    background-color: transparent;
    border-color: transparent;
}
