.footer{
    width: 100%;
}


.footer__container ul{
    display: flex;
}

.footerLink{
    text-decoration: none;
    color: white;
    font-size: x-small;
    font-weight: 400;
    margin: 0px 25px 0px 25px;
    position: relative;
    left: 5px;
}

.footerLink__pressKit{
    text-decoration: none;
    background-color: rgba(0, 0, 0, .3);
    color: white;
    font-size: 12px;
    letter-spacing: 1px;
    padding: 5px 15px 5px 35px;
}


@media (max-width: 650px) {

    .footer{
        margin-top: 55px;
        position: relative;
        bottom: 0px;

    }


    .footer__container ul{
        justify-content: space-between;
    }

    .footerLink__pressKit{

    }

    .footerLink{
        margin: 0px;
        font-size: 9px;
    }



}
