th{
    color: chocolate;
}

.dropzone{
    background-color: #f0f8f7;
    border: 1px solid #b0bab8;
    padding: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 25px;
}

.actionFormContainer__form {
    width: 100%;
    min-height: 300px;
    background-color: #e6f4f2;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;

    .inner-form {
        padding: 30px 20px;
    }
}

.dropzone input{
    height: 110px;
    width: 100%;

}

.filepicker{

    cursor: pointer;
    opacity: 0;
}

.inputOverlay{
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;

    pointer-events: none
}

.inputOverlay div{
    text-align: center;
    font-size: 12px;

}

.inputs-wrapper {
    input {
        width: 100%;
        margin-bottom: 12px;
        box-sizing: border-box;
        padding-left: 20px;
    }
}

.dropzone:hover{
    background-color: #8bb3c7;
    transition: .3s ease;
}

.fillFormContainer_form--input {
    background-color: #f0f8f7;
    border: 1px solid #b0bab8;
    height: 32px;
    padding: 0px 12px;
}

.fillFormContainer_form--input#name {
    margin-right: 10px;
}
