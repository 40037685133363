
.card-wrapper {
    background-color: #fff;
    width: 100%;
    max-width: 1200px;
    margin: 0 auto 5%;
    border-radius: 15px;
    padding: 20px;
}

.settingsContainer__header{
    color: #4fa7d6;
    font-size: 24px;
    font-weight: bold;
}

.settingsContainer__header--form{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
}

.settingsContainer__section{
    display: flex;
    flex-direction: column;
    margin: 15px 15px;
    width: 21%;
}

.settingsContainer__section input{
    padding: 10px;
    margin-top: 10px;
    font-size: 16px;

}
.settingsContainer__button{
    padding: 15px;
    background-color: #37A7D0;
    border-color: transparent;
    color:white;
    font-size: 18px;
    margin-top: 15%;
}

.tab-content {
    padding: 20px 10px;
}
