.actionFormContainer_verify {
    box-shadow: -7.9px -6.2px 9.4px 0.6px rgba(23, 31, 31, 0.21), 8.1px 5.9px 9.4px 0.6px #193d44;
    border-radius: 5px;
    width: 630px;
}

.actionFormContainer__form {
    width: 100%;
    // margin-top: 20px;
    min-height:300px;
    background-color: #e6f4f2;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;

    .inner-form {
        padding: 30px 20px;
    }
}

.actionFormContainer {
    position: relative;
}

.mt-5{
    margin-top: 10px;
}

.text-style{
    background-color: darkslategray;;
    min-height: 31px;
    line-height: 31px;
    border-radius: 5px;
    padding-left: 12px;
    text-align: left;
}
