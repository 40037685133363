.main-container {
    max-width: 1500px;
    min-height: 80vh;
    padding: 30px 0;
}

.bodyFormContainer__form {
    padding: 30px 12px;
    max-width: 475px;
}

.bodyFormContainer__body {
    padding: 30px 12px;
}

.welcomeTextContainer{
    letter-spacing: 1.5px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: white;
    font-weight: 300;
}

.welcomeTextContainer div{
    letter-spacing: 3px;
    font-size: 55px;
}

.welcomeTextContainer p {
    line-height: 0;
}

.welcomeTextContainer__buttonGroup{
    display: flex;
    justify-content: space-around;
    position: relative;
    top: 15px;
    left: 10%;
    width: 80%;
}

.welcomeTextContainer__buttonGroup a{
    color: white;
    text-decoration: none;
    border-width: 1px;
    padding: 5px 20px;
    border-style: solid;
}

.welcomeTextContainer__buttonGroup button{
    font-size: 16px;
    padding: 8px 20px;
    background-color: transparent;
    border-style: solid;
    border-color: white;
    color: white;
    border-width: 1px;
}
