.actionFormContainer {
    box-shadow: -7.9px -6.2px 9.4px 0.6px rgba(23, 31, 31, 0.21), 8.1px 5.9px 9.4px 0.6px #193d44;
    border-radius: 5px;
}

.actionFormContainer__form {
    width: 100%;
    min-height: 500px;
    background-color: #e6f4f2;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;

    .inner-form {
        padding: 30px 20px;
    }
}

.actionFormContainer {
    position: relative;
}

.icon-arrow {
    // background-image: url('../../styles/assets/arrow.png');
    background-image: url('../../styles/assets/arrow-final_longpause.gif');
    background-repeat: no-repeat;
    background-size: contain;
    width: 400px;
    height: 150px;
    position: absolute;
    top: -140px;
    left: 140px;

    @media (max-width: 920px){
        display: none;

    }
}
