.form-nav {
    width: 100%;
    display: flex;
    justify-content: space-around;

    span {
        font-family: "ConneqtRegular";
        background-color: #819999;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        height: 50px;
        font-size: 16px;
        text-transform: uppercase;
        text-decoration: none;
        color: #4d7a82;

        &.active {
            background-color: #e6f4f2;
        }

        &:first-of-type {
            border-top-left-radius: 15px;
        }
        &:last-of-type {
            border-top-right-radius: 15px;
        }
    }
}

@media (max-width: 650px) {

    .linkTag {
        font-size: 1rem;
    }

}
