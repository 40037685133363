.navBar{
    min-height: 17vh;
    display: flex;
    align-items: center;
}

.navBar__container{
    margin: 0px 2% 0px auto;

}

.navBar__container ul{
    display: flex;
    list-style: none;
    justify-content: center;
    margin: 0px;
}

.link{
    color: white;
    font-weight: 100;
    text-decoration: none;
    margin: 0px 25px 0px 25px;
}

.navBar__container .home{

    height: 26px;
    position: relative;
    bottom: 3px;
}



@media (max-width: 650px){

    .navBar__container{

        width: 100%;
    }

    .navBar{
        position: relative;
        left: -15px;
        min-height: 10vh;
    }

    .navBar__container ul{
        justify-content: space-between;
    }

    .navBar__container .home{

        height: 10px;

        position: relative;
        bottom: 10px;
    }

    .link{
        margin: 0px;
        font-size: 11px;
    }
}
